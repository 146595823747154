export const enTranslation = {
  learn: "Learn",
  market: "Market",
  analysis: "Analysis",
  helpCenter: "Help Center",
  homePage: "Home",
  moreContentOnTheMedias: "More content on the medias:",
  articlesThatWillInterest: "Articles that will interest you:",
  seeMore: "See more",
  seeAll: "See all",
  loadMore: "Load More",
  noMoreResults: "No more results",
  enterYourSearchHere: "Enter your search here...",
  theZeroFeeExchange: "The *zero fee** exchange.",
  openYourAccount: "Open your account",
  findHere: "Find Here",
  custodyInsurence: "Safe Custody",
  yourAssetsProtected: "Your assets secured with #Fireblocks#, a global custody reference.",
  fastTradingSpeeds: "Fast trading speeds without charging any fees.",
  apiDocumentation: "API documentation",
  allTradesBecomeRewards:
    "All trades become rewards in DGTA. And you win twice: by number of traders and volume traded on the platform that day.",
  builtInAuthenticator: "Integrated Authenticator",
  noMoreMultipleApps:
    "Our two-step verification is done #directly in the app#, making accessing your account easier.",
  faceIdAsKey: "Security Selfie",
  youAreTheOnlyKey:
    "We use a sophisticated biometric system. You are the #only key# to your vault.",
  exploreTheRevolution: "Explore the revolution.",
  getToKnowDigitra: "Get to know digitra.com",
  allAroundTheWorld: "All around the world",
  digitraImpactsFinancial:
    "Digitra.com's impacts financial markets worldwide and, of course, is in the heart of Wall Street.",
  learnMoreAboutDigitra: "Learn more about Digitra",
  joinUs: "Join our community!",
  giveAwaysFirstHandNews:
    "Give-aways, first-hand news, market insights and pro tips about our services.",
  connectWithTheCommunity:
    "Connect with the community, get tips and alerts in real time with the market.",
  lotsOfContentAboutUs:
    "Lots of content about us and our services, curiosities about the world of crypto.",
  freshContenteMadeByOurExperts:
    "Follow our news and access educational content",
  weSpecializeInServing:
    "We specialize in serving institutional clients and high net-worth individuals who need to fill large orders.",
  ourFocusIsOnProviding:
    "Our focus is on providing a private and personalized experience tailored to their specific needs.",
  withOurOtcDesk:
    "With our OTC desk, expect secure, competitive, and confidential execution and settlement services.",
  sendEmailTo: "Send an email to",
  withTheContactOfYourChoice:
    "with the contact of your choice (email or whatsapp) to receive help from our specialist.",
  howOtcTradingCanUnleash:
    "How OTC trading can unleash your financial potential?",
  itsSoSimple: "It’s so simple!",
  ourClientsAcrossTheGlobe:
    "Our clients across the globe experience unparalleled liquidity guarantees.",
  withExclusiveNetworkRates:
    "With exclusive network rates, we eliminate unnecessary expenses.",
  receiveSupportFromSpecialists:
    "Receive support from specialists at every stage of your trading journey.",
  talkToOneOfOurSpecialists: "Talk to one of our specialists today",
  stepByStep: "Step by step",
  reachOutToUsNow: "Reach out to us now",
  fillInTheFormToStart:
    "Fill in the form to start our journey together. One of our specialists will get in touch soon.",
  insideTheAction: "Inside the action",
  usingTheChannelOfYourChoice:
    "Using the channel of your choice, you will follow the trading experience, with the spreads, fees and suppliers worldwide.",
  theFinishLine: "The finish line",
  OurSkilledTrader:
    "Our skilled traders will find the best price - always - and as soon as we receive your funds, we close the deal.",
  transparencyCompliance: "Transparency and Compliance",
  wellTransferTheDesiredAsset:
    "We’ll transfer the desired asset to your chosen wallet address or keep it in your account here with us, where you have assurance against attacks.",
  createAccount: "Create Account",
  digitraAuthenticator: "Digitra.com Authenticator",
  helpCenterPage: "Help center",
  privacyCookie: "Privacy & Cookie Policies",
  termsUse: "Terms of Use",
  feesLimits: "Fees and limits",
  begginer: "beginner",
  intermediate: "intermediate",
  advanced: "advanced",
  tradeToEarnExclusivity: "Trade to Earn exclusivity!",
  pageNotFound: "Page not found",
  zeroFeeWithDGTA: "* For accounts with a minimum balance of 2500 DGTA tokens.",
  digitraApplicableLaw: "Digitra.com complies with all applicable laws and regulations in Brazil. In accordance with Article 41 of Law No. 13,709 of 2018 (General Data Protection Law), Digitra.com appoints Vanessa Rodrigues as the data processing officer.",
  openAndEarn: "Sign up and get tokens *DGTA*",
  openMyAccount: "SIGN UP!",
  globalExchange: "#Cryptocurrency# Exchange in Over 170 countries.",
  aboutDGTA: "Digitra.com's native ERC-20 token offers daily rewards in the #Trade to Earn# program.",
  aboutTradeToEarn: "Get DGTA cashback for every trade, every day. \n#Simple#: buy or sell any currency and earned DGTA. Accumulate tokens daily!",
  learnMore: "Learn more!",
  zeroFee: "*Zero Fee*",
  zeroFeeExplanation: "DGTA token holders enjoy zero fees on #all pairs#.",
  api: "API",
  goToDocumentation: "See documentation",
  apiText: "Enable #faster# and automated trades.",
  feePageTitle: "Digitra.com Fee Policy",
  tradeFeeSubtitle: "Trading Fee",
  withdrawFeeSubtitle: "Withdrawal Fee",
  withdrawFeeParagraph: "Digitra.com does not charge fees for fiat currency withdrawals, such as the Brazilian Real, only for cryptocurrency withdrawals when you want to transfer a cryptocurrency from digitra.com, allowing you to transfer it to another exchange or crypto wallet. This is a standard practice on cryptocurrency exchanges.",
  howDoesItWork: "How does it work?",
  withdrawFeeExplanation: "The withdrawal fee comprises the blockchain network fee and operational service fees. The network fee varies depending on the blockchain used for the transfer. The service fee amounts to *20% of the network fee*, meaning the total fee also depends on the network you choose for withdrawal.",
  withdrawFeeImportant: "*Important:* For all cryptocurrencies, the minimum withdrawal fee is *$1*. See the examples below:",
  tradeFeeWithoutMinDGTA: "The fee for trading any crypto pair on digitra.com is 0.1% for market makers and 0.5% for market takers. The fee is charged as follows:",
  tradeFeeWithoutMinBullet: "*0.1% or 0.5% of the traded volume in the transaction—the amount deducted from the cryptocurrency credited to your account.*",
  tradeFeeExample: "*Example:* If you have a balance of $10 and use it to buy Bitcoin, the fee will be charged on the Bitcoin credited. Once the purchase is complete, your Bitcoin balance will be in your wallet with the fee amount deducted.",
  examples: "Examples",
  crypto: "Cryptocurrency",
  blockchainFee: "Network Fee",
  totalFee: "Total Fee",
  cryptoCase1: "Ethereum (ETH) - ERC-20 Network",
  cryptoCase2: "MATIC on Polygon Network",
  blockchainFeeCase1: "0.003253 ETH (approximately $7)",
  blockchainFeeCase2: "0.567774 MATIC (approximately $0.50)",
  totalFeeCase1: "Total Fee: $8.4 (including 20% service fee)",
  totalFeeCase2: "Total Fee: $1 (meeting the minimum withdrawal fee)",
  minDGTAZeroFeeLink: "200 DGTA tokens",
  seeMoreAbountTradeFee: "Find more details about Zero Fee Trading here!",
  tradingFeeWithMinDGTAStart: "Customers maintaining a minimum of ",
  inYourWallet: " in their digitra.com wallet enjoy exemption from spot trading fees.",
  fees: "Fees",
  makerAndTaker: "What is Market Maker and Market Taker?",
  makerAndTakerResume: "In the context of the cryptocurrency market, the terms *\"maker\"* and *\"taker\"* refer to two distinct participants in the trading process. The market maker, responsible for creating liquidity, places limit orders by predefining asset values for buying or selling. Their role involves providing liquidity to the market by offering orders waiting for matches. On the other hand, the market taker accepts orders previously set by the market maker. The taker's role is to execute market orders, responding to buy or sell requests based on the current asset prices.",
  makerAndTakerOrderTypes: "To better understand these roles, it is essential to grasp the differences between market orders and limit orders. Market orders are executed based on the current asset prices, while limit orders have values predetermined by the buyer or seller. Therefore, the market maker is responsible for creating limit orders, contributing to the formation of the cryptocurrency order book, while the market taker uses market orders to conduct transactions. This distinction not only influences the trading process but also affects the fees charged for services, underscoring the significance of these two participants in the cryptocurrency ecosystem.",
  cryptoDividends: "Cripto Dividends",
  cryptoDividendsText: "Your idle balance generates income every month, paid in DGTA tokens.",
  openAccount: "Sign Up",
  liquidityProvider: "Liquidity Provider",
  liquidityProviderText: "Your cryptocurrency buy or sell orders generate income.",
  readLitePaper: "Read the Litepaper!",
  liquidityLitepaper: "Liquidity Provider Litepaper",
  simpleSecurePlatform: "Simplified and secure platform",
  earnCrypto: "Earn cryptocurrencies",
  tokenDgta: "DGTA Token",
  tokenDgtaText: "DGTA is our token, distributed in reward programs on the platform and used to reward our customers.",
  blockchain: "Blockchain",
  blockchainText: "DGTA is a #token ERC-20 #from #Ethereum network#",
  supply: "Max Supply",
  supplyText: "300 million units",
  burnPolicy: "Burn policy",
  burnPolicyText: "50% of the maximum supply #will be burned#",
  listing: "Listing",
  listingText: "Listed on #Digitra.com# with pairs #BRL# and #USDT#",
  buyBtcEthAndMore: "Buy Bitcoin, Ethereum and more than 40 cryptocurrencies in a simplified and secure way",
  exchangeThatPaysToUse: "Crypto exchange that #pays you# to use! You #earn tokens# for opening account, depositing, negotiating and indicating.",
  start: "Start",
  startNowEarnDgta: "Start now and win up to 100 DGTA!",
  newAccount: "Bonus in account opening",
  newAccountText: "Earn up to 100 DGTA tokens as soon as you complete your registration.",
  referralProgram: "Referral program",
  referralProgramText: "Indicate Digitra.com and earn reward DGTA tokens.",
  tradeEarn: "Trade to Earn",
  tradeEarnText: "With just one trade you get your part in the daily AirDrop of 5,000 DGTA tokens.",
  tradersLeague: "Traders League",
  tradersLeagueText: "Earn up to 3,000 DGTA tokens negotiating large volumes in less busy currency pairs.",
  iWantToEarn: "I want to win!"
}
